import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { animated } from '@react-spring/web';

import { setColor } from '../../../../redux/actions';
import { State } from '../../../../redux/types';

import Card from '../../../../components/Card/card';

import { StyledPage, StyledSelection, StyledTitle } from './colorPage.styled';

import { productList, Color, ColorNames, ProductVariants, MaterialProductVariantMap, MaterialColorMap } from '../../../../common/products';


type Props = {
    style: any
}

const ColorPage: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();
    const { product, material, color } = useSelector((state: State) => state.configurator);

    const isCardSelected = (id: string) => {
        if (color) {
            return id === color;
        }
        return true;
    }

    const getFirstVariant = (): ProductVariants => {
        return Object.entries(MaterialProductVariantMap[material!!])[0][1];
    }

    return (
        <StyledPage as={animated.div} style={props.style}>
            <StyledTitle>Wählen Sie die Farbe für {productList[product!!].pronoun} {productList[product!!].name}:</StyledTitle>
            <StyledSelection>
                {MaterialColorMap[material!!].map((key) => (
                    <Card image={productList[product!!].images[getFirstVariant()]}
                          color={key as Color}
                          text={ColorNames[key as Color]}
                          onClick={() => dispatch(setColor(key as Color))}
                          key={key}
                          selected={isCardSelected(key)} />
                ))}
            </StyledSelection>
        </StyledPage>
    )
}

export default ColorPage