import styled from "styled-components";

import StyledStepPage from '../stepPage.styled';

interface Props {

}

export const StyledPage = styled(StyledStepPage.StyledPage)<Props>`

`

export const StyledTitle = styled(StyledStepPage.StyledTitle)`
    
`

export const StyledSelection = styled(StyledStepPage.StyledSelection)`
    
`