import styled from 'styled-components';
import theme from '../../themes/default.theme';
import { lighten } from 'polished';

export const StyledOfferTable = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
`

export const OfferTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px ${theme.colors.shadow};
    overflow: hidden;
    border-radius: 15px;
`

export const OfferTableItem = styled.div`
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    align-items: center;
    align-content: center;
    text-align: left;
    font-size: 1rem;
    padding-left: 5px;
    padding-right: 5px;
    /* height: 100%; */
    height: fit-content;
    max-height: fit-content;
    width: calc(100% - 10px);
    pointer-events: none;
    user-select: none;
`

export const OfferTableRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 6fr 2.2fr;
    min-height: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 10px;
    width: calc(100% - 15px);
    flex-shrink: 0;
    ${OfferTableItem}: first-child {
        padding-left: 0;
        width: calc(100% - 10px);
    }
    ${OfferTableItem}: last-child {
        padding-right: 0;
        width: calc(100% - 10px);
    }
`

export const OfferTableHeader = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    background-color: ${theme.colors.secondary};
    border-bottom: 1px solid ${theme.colors.border};
    ${OfferTableRow} {
        min-height: 30px;
        ${OfferTableItem} {
            font-family: SemiBold;
        }
    }
`


export const OfferTableBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    ${OfferTableRow} {
        ${OfferTableItem}: first-child {
            text-align: center;
            justify-content: center;
        }
        ${OfferTableItem}: last-child {
            text-align: right;
            justify-content: flex-end;
        }
    }
    ${OfferTableRow}:nth-child(even) {
        background-color: ${lighten(0.05, theme.colors.secondary)};
    }
    ${OfferTableRow}:last-child {
        border-top: 1px solid ${theme.colors.border};
        background-color: ${theme.colors.primary};
        ${OfferTableItem} {
            font-family: SemiBold;
        }
    }
`