import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { animated, useSpring } from '@react-spring/web';
import { IoMdArrowRoundBack } from 'react-icons/io';

import { State } from '../../redux/types';
import { goBack } from '../../redux/actions';

import { StyledPageHeader, StyledLogo, StyledTitle, StyledMobileBackButton, StyledAnchor } from './pageHeader.styled';

import Logo from '../../assets/logo2.png';

interface Props {

}

export const PageHeader: React.FC<Props> = (props: Props) => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const dispatch = useDispatch();
    const { step } = useSelector((state: State) => state.configurator);

    const animation = useSpring({
        from: { transform: 'translate3d(0,-100%,0)' },
        to: { transform: 'translate3d(0,0%,0)' },
    })

    return (
        <StyledPageHeader as={animated.div} style={animation}>
            <StyledAnchor href="https://www.zauninger.at">
                <StyledLogo src={Logo} alt="Logo" />
            </StyledAnchor>
            <StyledTitle>{isMobile ? 'Zauninger Konfigurator' : 'Zauninger Konfigurator für Zäune und Tore'}</StyledTitle>
            {isMobile && <StyledMobileBackButton active={step > 1} onClick={() => step > 1 ? dispatch(goBack()) : null} >
                <IoMdArrowRoundBack />
            </StyledMobileBackButton>}
        </StyledPageHeader>
    )
}

export default PageHeader