import styled from "styled-components";
import theme from "../../themes/default.theme";

import { OfferTableContainer } from "../OfferTable/offerTable.styled"

export const StyledSummary = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.primary};
    border-radius: 30px;
    border: 1px solid ${theme.colors.border};
    box-shadow: 0px 5px 15px 0px ${theme.colors.shadow};
    overflow: hidden;
`

export const SummaryTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 50px;
    background-color: ${theme.colors.accent};
    font-family: SemiBold;
    font-size: 1.5rem;
    user-select: none;
    pointer-events: none;
    flex-shrink: 0;
`

export const SummaryContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 20px;
    width: 100%;
    height: calc(100% - 25px);
    overflow: overlay;
    overflow-x: hidden;
    ${OfferTableContainer} {
        margin-top: 20px;
    }
`

export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid ${theme.colors.border};
    width: 100%;
    height: 50px;
    flex-shrink: 0;
`