import styled from "styled-components";
import theme from "../../themes/default.theme";

interface Props {

}

export const StyledSelectionContainer = styled.div<Props>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: calc(100% - 20px);
`;

export const StyledSelectionTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 5px;
    margin-top: 5px;
    font-family: SemiBold;
    user-select: none;
    pointer-events: none;
`;

export const StyledSelectionGroup = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
`;

interface ItemProps {
    selected: boolean;
}

export const StyledSelectionGroupItem = styled.div<ItemProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    width: 100px;
    height: 40px;
    font-size: .8rem;
    margin: 5px;
    border: 1px solid ${theme.colors.border};
    border-radius: 30px;
    background-color: ${({ selected }) => selected ? theme.colors.accent : theme.colors.primary};
    font-family: ${({ selected }) => selected ? 'SemiBold' : 'Regular'};
    box-shadow: 1px 1px 5px ${({ theme }) => (theme.colors.shadow)};
    user-select: none;
    &:hover {
        border: 1px solid ${theme.colors.accent};
    }
`;