import React from 'react';
import { animated, useSpring } from '@react-spring/web';

import { StyledPageFooter, StyledFooterText} from './pageFooter.styled';

import { useMediaQuery } from 'react-responsive';


interface Props {

}

export const PageFooter: React.FC<Props> = (props: Props) => {
    const animation = useSpring({
        from: { transform: 'translate3d(0,100%,0)' },
        to: { transform: 'translate3d(0,0%,0)' },
    })

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <StyledPageFooter as={animated.div} style={animation}>
            <StyledFooterText>
                {!isMobile ? `Copyright © Uwe Thomas GmbH | by Werbeagentur HuK ${new Date().getFullYear()} | ` : `© Uwe Thomas GmbH | ${new Date().getFullYear()} | `}
                <a href='/impressum'>Impressum</a>
            </StyledFooterText>
        </StyledPageFooter>
    )
}

export default PageFooter