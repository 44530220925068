import { jsPDF } from 'jspdf';

import { ConfigList } from '../redux/types';

const moneyStringOptions = {
    style: 'currency',
    currency: 'EUR',
}

export const createConfigPDF = (cl: ConfigList) => {
    // WIDTH -->    210mm begin measured
    // HEIGHT -->   297mm EOL measured
    /*
    let zaun = new Product(10,'Zaun NAME NAME',157.90)

    let pfosten = new Product(11,'Pfosten NAME NAME',54.90)

    let betonsockelbrett = new Product(10,'Sockelbrett NAME NAME',38.90)

    let configProduct = new ConfigProduct([zaun, pfosten, betonsockelbrett],'anthrazit',13117.82,19.5)

    let configList = new ConfigList([configProduct, configProduct],'abc1234')
    //cl = configList; // for testing
    cl = JSON.parse(testConfigString); // for testing
    */
    let headerTextStart = 16;
    let headerHorStart = 130;
    const pdf = new jsPDF('p', 'mm', 'a4');


    cl.configs.forEach((config, index) => {
        // new page
        if(index !== 0){
            pdf.addPage();
        }

        let mountingCosts = 0;
        if (config.length !== undefined) {
            mountingCosts = config.length*50;
        } else {
            mountingCosts = 1100;
        }

        // header
        pdf.setFontSize(12);
        pdf.text('Der Zauninger', headerHorStart, headerTextStart);
        pdf.text('Fa. Uwe Thomas', headerHorStart, headerTextStart+4.5);
        pdf.text('Greithweg 35, 8403, Lebring', headerHorStart, headerTextStart+9);
        pdf.text('Tel.: +43-(0)680 31 21 546', headerHorStart, headerTextStart+13.5);
        pdf.text('E-Mail: info@zauninger.at', headerHorStart, headerTextStart+18);
        pdf.addImage(cl.logoURL, 'PNG', 25, 10, 42.91, 27.1);
        pdf.text('_'.repeat(90), 0, 43);

        // config id /  color
        pdf.setFontSize(14);
        pdf.text('ID:', 85, 52);
        pdf.text(cl.dbId, 100, 52);
        pdf.text('Farbe:', 77, 58);
        pdf.text(config.color, 100, 58);

        // per product
        let firstVerticalMarker = 62;
        config.products.forEach((product, index) => {

            if (product.name.includes('Betonsockel')) {
                mountingCosts += product.quantity * 32;
            } else if (product.name.toLowerCase().includes('gehtür')) {
                mountingCosts = 310;
            }

            pdf.setFontSize(14);
            pdf.text('_ '.repeat(70), 0, firstVerticalMarker);
            pdf.text(product.name, 20, firstVerticalMarker+10);
            pdf.text('Anzahl: ' + product.quantity, 20, firstVerticalMarker+17.5);
            pdf.setFontSize(15);
            pdf.setTextColor(100,100,100);
            pdf.text(product.price.toLocaleString('de-DE', moneyStringOptions), 170, firstVerticalMarker+13.5);
            pdf.setFontSize(14);
            pdf.setTextColor(0,0,0);
            pdf.text('_ '.repeat(70), 0, firstVerticalMarker+22);
            firstVerticalMarker += 25;
        });

        // product image
        pdf.addImage(config.imageURL, 'PNG', 65, 160, 80, 80);

        // total price
        pdf.setFontSize(17);
        pdf.setTextColor(100,100,100);
        pdf.text('Gesamtpreis', 60, 275);
        pdf.setTextColor(0,0,0);
        pdf.setFontSize(20);
        pdf.text(config.totalPrice.toLocaleString('de-DE', moneyStringOptions), 120, 275);

        // mounting costs
        pdf.setFontSize(14);
        pdf.setTextColor(100,100,100);
        pdf.text('geschätzte Montagekosten',35,283.5);
        pdf.setTextColor(0,0,0);
        //pdf.text(122,283.5,mountingCosts + ',00 €');
        pdf.text(mountingCosts.toLocaleString('de-DE', moneyStringOptions),122,283.5);

        // footer
        pdf.setFontSize(10);
        pdf.text('Bei Fragen / Interesse melden Sie sich gerne per Telefon / Mail / persönlich bei uns!', 42, 291)
        pdf.text('Alle Preise sind ausschließlich als Annäherungen / Preisvoranschläge zu verstehen und UNVERBINDLICH.', 22, 295);
    });
    
    pdf.save('Zauniger-Konfiguration-' + cl.dbId + '.pdf');
}