import styled from "styled-components";
import theme from "../../themes/default.theme";
import { lighten } from "polished";

export enum CardSize {
    normal = 'normal',
    small = 'small'
}

interface Props {
    size: CardSize;
}

const Card = styled.div<Props>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    max-width: 300px;
    min-width: 300px;
    min-height: 370px;
    max-height: 370px;
    border: 1px solid ${theme.colors.border};
    border-radius: 10px;
    overflow: hidden;
    margin: 10px;
    box-shadow: 0 5px 15px ${theme.colors.shadow};
    background-color: ${theme.colors.primary};
    &:hover {
        border: 1px solid ${theme.colors.accent};
    }
`;

const Header = styled.div`
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    background-color: ${theme.colors.accent};
    display: flex;
`

const BackBtn = styled.div`
    width: 26px;
    height: 26px;
    margin: 10px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border 2px solid ${theme.colors.primary};
    background-color: ${theme.colors.primary};
    color: ${theme.colors.accent};
    svg {
        width: 22px;
        height: 22px;
    }
    &:hover {
        color: ${theme.colors.primary};
        background-color: ${theme.colors.accent};
    }
`

const HeaderText = styled.div`
    width: 100%;
    height: 100%;
    margin-right: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    pointer-events: none;
    font-family: SemiBold;
    font-size: 1.2rem;
`

interface SelectionProps {
    count: number;
    active: boolean;
}

const Selection = styled.div<SelectionProps>`
    width: 100%;
    height: calc((370px - 50px) / ${props => props.count});
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.accent};
    background-color: ${({ active }) => active ? lighten(0.05,theme.colors.secondary) : theme.colors.primary};
    &:hover {
        background-color: ${lighten(0.05,theme.colors.secondary)};
    }
`

const SelectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: calc(370px - 50px);
    flex-grow: 1;
    ${Selection}:last-child {
        border-bottom: none;
    }
`

const ImageContainer = styled.div`
    width: calc(35% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

const Text = styled.div`
    width: 65%;
    height: 100%;
    padding-left: 10px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
    pointer-events: none;
    font-family: SemiBold;
    font-size: 1.2rem;
`


const StyledSelectionCard = {
    Card,
    Header,
    BackBtn,
    HeaderText,
    SelectionContainer,
    Selection,
    ImageContainer,
    Image,
    Text
};

export default StyledSelectionCard;