import React from 'react';
import { animated } from '@react-spring/web';

import { StyledMobileNavigation } from './mobileNavigation.styled';

import NavStep from './NavStep/navStep';

import Steps, { NavStepType } from '../steps';

interface Props {
    style: any
}

export const Navigation: React.FC<Props> = (props: Props) => {
    return (
        <StyledMobileNavigation as={animated.div} style={props.style}>
            {Steps.map((step: NavStepType) => <NavStep key={step.id} number={step.id} step={step.step} title={step.title} />)}
        </StyledMobileNavigation>
    )
}

export default Navigation