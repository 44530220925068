import React from 'react'

import { StyledSelectionContainer, StyledSelectionTitle, StyledSelectionGroup, StyledSelectionGroupItem } from './selectionGroup.styled'

export interface SelectionOption {
    id: any;
    label: string;
}

type Props = {
    title: string,
    options: SelectionOption[],
    state: any,
    onClick: (id: any) => void
}

const SelectionGroup = (props: Props) => {
    if (props.options.length <= 1) return null;
    return (
        <StyledSelectionContainer>
            <StyledSelectionTitle>{props.title}</StyledSelectionTitle>
            <StyledSelectionGroup>
                {props.options.map((option, index) => {
                    return (
                        <StyledSelectionGroupItem
                        onClick={() => props.onClick(option.id)}
                        key={index}
                        selected={props.state === option.id}>{option.label}</StyledSelectionGroupItem>
                    )
                })}
            </StyledSelectionGroup>
        </StyledSelectionContainer>
    )
}

export default SelectionGroup