import React from 'react'
import { useSelector } from 'react-redux';
import { IoMdArrowRoundBack } from 'react-icons/io';

import { State } from '../../redux/types';

import StyledSelectionCard, {CardSize} from './selectionCard.styled';

export {CardSize} from './selectionCard.styled';

export interface Selection {
    id: any;
    image: string;
    text: string;
    onClick: () => void;
}

interface Props {
    text: string;
    back: () => void;
    selections: (Selection | undefined)[];
    size?: CardSize;
}

export const SelectionCard: React.FC<Props> = (props: Props) => {
    const { product } = useSelector((state: State) => state.configurator);
    return (
        <StyledSelectionCard.Card size={props.size || CardSize.normal}>
            <StyledSelectionCard.Header>
                <StyledSelectionCard.BackBtn onClick={props.back}>
                    <IoMdArrowRoundBack />
                </StyledSelectionCard.BackBtn>
                <StyledSelectionCard.HeaderText>{props.text}</StyledSelectionCard.HeaderText>
            </StyledSelectionCard.Header>
            <StyledSelectionCard.SelectionContainer>
                {props.selections.map((option, index) => (
                    <StyledSelectionCard.Selection active={product === option?.id} count={props.selections.length} key={index} onClick={option?.onClick}>
                    <StyledSelectionCard.ImageContainer>
                        <StyledSelectionCard.Image src={option?.image} alt="CardImage" />
                    </StyledSelectionCard.ImageContainer>
                    <StyledSelectionCard.Text>{option?.text}</StyledSelectionCard.Text>
                </StyledSelectionCard.Selection>
                ))}
            </StyledSelectionCard.SelectionContainer>
        </StyledSelectionCard.Card>
    )
}

export default SelectionCard