import styled from "styled-components";
import theme from "../../../../themes/default.theme";

interface Props {
    isCurrent: boolean;
    isActive: boolean;
}

export const StyledStepIcon = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    font-family: SemiBold;
    user-select: none;
    pointer-events: none;
`

export const StyledStepTitle = styled.p`
    display: none;
    margin-left: 10px;
    margin-right: 10px;
    font-family: SemiBold;
    user-select: none;
    pointer-events: none;
`

export const StyledStep = styled.div<Props>`
    height: 40px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding-left: 6.5px;
    padding-right: 6.5px;
    border-radius: 30px;
    border: 2px solid ${({isCurrent}) => isCurrent ? theme.colors.primary : theme.colors.accent};
    background-color: ${({isCurrent}) => isCurrent ? theme.colors.primary : theme.colors.accent};
    ${StyledStepIcon} {
        background-color: ${({isCurrent, isActive}) => isCurrent ? theme.colors.accent : isActive ? theme.colors.primary : theme.colors.deactivated};
        color: ${({isCurrent}) => isCurrent ? theme.colors.primary : theme.colors.accent};
    }
    ${StyledStepTitle} {
        color: ${({isCurrent, isActive}) => isCurrent ? theme.colors.accent : isActive ? theme.colors.primary : theme.colors.deactivated};
        display: ${({isCurrent}) => isCurrent ? 'block' : ''};
    }
    &:hover {
        border: ${({isCurrent}) => !isCurrent ? `2px solid ${theme.colors.primary}` : ''};
        ${StyledStepTitle} {
            display: block;
        }
        transition: width 0.2s ease-in-out;
        overflow: hidden;
    }
`