import styled from "styled-components";
import theme from "../../themes/default.theme";

interface Props {
    disabled?: boolean;
}

export const StyledButton = styled.div<Props>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    width: 150px;
    height: 40px;
    font-size: .8rem;
    margin: 5px;
    border: 1px solid ${theme.colors.border};
    border-radius: 30px;
    background-color: ${({ theme, disabled }) => disabled ? theme.colors.border : theme.colors.accent};
    color: ${({ theme, disabled }) => disabled ? theme.colors.deactivated : 'black'};
    font-family: SemiBold;
    box-shadow: 1px 1px 5px ${({ theme }) => (theme.colors.shadow)};
    user-select: none;
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
    &:hover {
        border: 1px solid ${theme.colors.accent};
        background-color: ${theme.colors.primary};
    }
`