import React, { useState } from 'react'

import StyledCard, {CardSize} from './card.styled';

import { Material, Color } from '../../common/products'

export {CardSize} from './card.styled';

interface Props {
    image: string;
    text: string;
    selected: boolean;
    size?: CardSize;
    slideOutText?: string;
    color?: Color | undefined | null;
    material?: Material | undefined | null;
    onClick?: () => void;
}

export const Card: React.FC<Props> = (props: Props) => {
    const [hovered, setHovered] = useState(false);

    return (
        <StyledCard.Card selected={props.selected} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} size={props.size || CardSize.normal} onClick={props.onClick}>
            <StyledCard.Image src={props.image} alt="CardImage" color={props.color ? props.color : undefined} material={props.material ? props.material : undefined} />
            <StyledCard.Text>{props.text}</StyledCard.Text>
            {props.slideOutText && <StyledCard.SlideOut slideOut={hovered}>{props.slideOutText}</StyledCard.SlideOut>}
        </StyledCard.Card>
    )
}

export default Card