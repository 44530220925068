import { Action, Actions, ProductModeMap, Size, ConfigObject } from "./types";
import { Product, Material, Color, ProductVariants, GateOption, PostOption, GateExtras, FenceExtras } from '../common/products';


export const gotoStep = (step: number): Action => {
    window.scrollTo(0, 0);
    return {
        type: Actions.gotoStep,
        payload: {
            step
        }
    }
}

export const goBack = (): Action => {
    window.scrollTo(0, 0);
    return {
        type: Actions.goBack
    }
}

export const setProduct = (product: Product): Action => {
    window.scrollTo(0, 0);
    return {
        type: Actions.setProduct,
        payload: {
            mode: ProductModeMap[product],
            product
        }
    }
}

export const setMaterial = (material: Material): Action => {
    window.scrollTo(0, 0);
    return {
        type: Actions.setMaterial,
        payload: {
            material
        }
    }
}

export const setColor = (color: Color): Action => {
    window.scrollTo(0, 0);
    return {
        type: Actions.setColor,
        payload: {
            color
        }
    }
}

export const setVariant = (variant: ProductVariants, isMobile:Boolean): Action => {

    if (isMobile) {
        document.getElementById('summary')?.scrollIntoView();
    } else {
        window.scrollTo(0, 0);
    }

    window.scrollTo(0, 0);
    return {
        type: Actions.setVariant,
        payload: {
            variant
        }
    }
}

export const setSize = (size: Size): Action => {
    return {
        type: Actions.setSize,
        payload: {
            size
        }
    }
}

export const setFenceLength = (fenceLength: number): Action => {
    return {
        type: Actions.setLength,
        payload: {
            fenceLength
        }
    }
}

export const setGateOption = (gateOption: GateOption): Action => {
    return {
        type: Actions.setGateOption,
        payload: {
            gateOption
        }
    }
}

export const setGateExtras = (gateExtras: GateExtras | null): Action => {
    return {
        type: Actions.setGateExtras,
        payload: {
            gateExtras
        }
    }
}

export const setPostOption = (postOption: PostOption): Action => {
    return {
        type: Actions.setPostOption,
        payload: {
            postOption
        }
    }
}

export const setFenceExtras = (fenceExtras: FenceExtras | null): Action => {
    return {
        type: Actions.setFenceExtras,
        payload: {
            fenceExtras
        }
    }
}

export const setCurrentConfig = (currentConfig: ConfigObject): Action => {
    return {
        type: Actions.setCurrentConfig,
        payload: {
            currentConfig
        }
    }
}

export const storeConfig = (): Action => {
    return {
        type: Actions.storeConfig
    }
}

export const storeConfigToDB = (): Action => {
    return {
        type: Actions.storeConfigToDB
    }
}

export const resetConfigurator = (): Action => {
    return {
        type: Actions.resetConfigurator
    }
}