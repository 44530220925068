import React from 'react';
import { useState, useEffect } from 'react';
import { animated, useSpring } from '@react-spring/web';


import { StyledBackgroundSlideshow, StyledBackgroundImage } from './backgroundSlideshow.styled';

import Image01 from '../../assets/slideshow/01.jpg';
import Image02 from '../../assets/slideshow/02.jpg';
import Image03 from '../../assets/slideshow/03.jpg';
import Image04 from '../../assets/slideshow/04.jpg';
import Image05 from '../../assets/slideshow/05.jpg';
import Image06 from '../../assets/slideshow/06.jpg';
import Image07 from '../../assets/slideshow/07.jpg';
import Image08 from '../../assets/slideshow/08.jpg';

const images = [Image01, Image02, Image03, Image04, Image05, Image06, Image07, Image08];


interface Props {
    
}

export const BackgroundSlideshow: React.FC<Props> = (props: Props) => {
    const [activeImage, setActiveImage] = useState(0);

    useEffect(() => {
        let i = 0;
        const interval = setInterval(() => {
            setActiveImage(i);
            i++;
            if (i === images.length) {
                i = 0;
            }
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    const mountAnimation = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 500 }
    });

    return (
        <StyledBackgroundSlideshow as={animated.div} style={mountAnimation}>
            {images.map((image, index) => {
                return <StyledBackgroundImage src={image} active={activeImage === index} key={index} />
            })}
        </StyledBackgroundSlideshow>
    )
}

export default BackgroundSlideshow