import { Product, Material, Color, ProductVariants, GateOption, PostOption, GateExtras, FenceExtras } from '../common/products';

export enum Step {
    'product' = 1,
    'material' = 2,
    'color' = 3,
    'options' = 4,
}

export enum Mode {
    'fence' = 1,
    'gateOrDoor' = 2
}

export const ProductModeMap = {
    [Product.fence]: Mode.fence,
    [Product.slidingGate]: Mode.gateOrDoor,
    [Product.gate]: Mode.gateOrDoor,
    [Product.door]: Mode.gateOrDoor,
    [Product.connectingDoor]: Mode.gateOrDoor,
}

export type Size = {
    width: number | null,
    height: number | null
}

export type ConfigProduct = {
    quantity: number,
    name: string,
    price: number,
}

export type ConfigObject = {
    products: ConfigProduct[],
    color: string,
    totalPrice: number,
    imageURL: string,
    length?: number,        // only for fences
}

export type ConfigList = {
    configs: ConfigObject[],
    logoURL: string,
    dbId: string,           // id of the config in the database
}

export type ConfiguratorState = {
    mode: Mode | null,
    step: Step,
    lastCompletedStep: Step | null,
    lastDisplayedStep: Step,
    product: Product | null,
    material: Material | null,
    color: Color | null
    variant: ProductVariants | null,
    gateOption: GateOption | null,
    gateExtras: GateExtras | null,
    postOption: PostOption | null,
    fenceExtras: FenceExtras | null,
    size: Size | null,
    fenceLength: number,
    currentConfig: ConfigObject | null,
    configList: ConfigObject[],
}

export type State = {
    configurator: ConfiguratorState
}

export type Payload = {
    step?: Step,
    mode?: Mode,
    material?: Material,
    product?: Product,
    color?: Color,
    variant?: ProductVariants,
    gateOption?: GateOption,
    gateExtras?: GateExtras | null,
    postOption?: PostOption,
    fenceExtras?: FenceExtras | null,
    size?: Size,
    fenceLength?: number,
    currentConfig?: ConfigObject,
}

export type Action = {
    type: Actions,
    payload?: Payload
}

export enum Actions {
    gotoStep = 'GOTO_STEP',
    goBack = 'GOBACK',
    setProduct = 'SET_PRODUCT',
    setMaterial = 'SET_MATERIAL',
    setColor = 'SET_COLOR',
    setVariant = 'SET_VARIANT',
    setGateOption = 'SET_GATE_OPTION',
    setGateExtras = 'SET_GATE_EXTRAS',
    setPostOption = 'SET_POST_OPTION',
    setFenceExtras = 'SET_FENCE_EXTRAS',
    setSize = 'SET_SIZE',
    setLength = 'SET_LENGHT',
    setCurrentConfig = 'SET_CURRENT_CONFIG',
    storeConfig = 'STORE_CONFIG',
    storeConfigToDB = 'STORE_CONFIG_TO_DB',
    resetConfigurator = 'RESET_CONFIGURATOR',
}