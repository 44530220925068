import React from 'react'

import { StyledButton } from './button.styled';

interface Props {
    children?: React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
}

export const button: React.FC<Props> = (props: Props) => {
    return (
        <StyledButton onClick={props.onClick} disabled={props.disabled} >
            {props.children}
        </StyledButton>
    )
}

export default button
