import styled from "styled-components";
import theme from "../../themes/default.theme";
import { lighten } from "polished";

interface Props {

}

export const StyledPageHeader = styled.div<Props>`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: start;
    text-align: left;
    height: 50px;
    width: 100vw;
    border-bottom: 1px solid ${theme.colors.accent};
    background-color: ${theme.colors.accent};
    box-shadow: 0 5px 15px ${theme.colors.shadow};
    @media (max-width: 768px) {
        box-shadow: none;
        border-bottom: 1px solid ${lighten(0.25,theme.colors.accent)};
        flex-direction: row-reverse;
    }
`

export const StyledLogo = styled.img`
    height: 50px;
    width: auto;
    user-select: none;
`

export const StyledAnchor = styled.a`
    height: 50px;
    margin: 10px;
`


export const StyledTitle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: SemiBold;
    font-size: 1.5rem;
    user-select: none;
    pointer-events: none;
    margin-right: 100px;
    white-space: nowrap;
    @media (max-width: 768px) {
        margin-right: 0;
        //font-size: 1.25rem;
        font-size: 5vw;
    }
`

interface BackButtonProps {
    active: boolean;
}

export const StyledMobileBackButton = styled.div<BackButtonProps>`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    //margin-left: 18.5px;
    //margin-right: 21.5px;
    margin-left: 3.5vw;
    margin-right: 3.5vw;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: ${({active}) => active ? theme.colors.primary : theme.colors.accent};
    border: 2px solid ${({active}) => active ? theme.colors.primary : theme.colors.accent};
    svg {
        color: ${({active}) => active ? theme.colors.accent : theme.colors.deactivated};
        height: 20px;
        width: 20px;
    }
    &:hover {
        background-color: ${({active}) => active ? theme.colors.accent : ''};
        border: ${({active}) => active ? `2px solid ${theme.colors.primary}` : ''};
        svg {
            color: ${({active}) => active ? theme.colors.primary : ''};
        }
    }
    @media (max-width: 360px) {
        margin-left: 2vw;
        margin-right: 2vw;
    }
`