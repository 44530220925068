import styled from "styled-components";

interface Props {

}

export const StyledConfigurator = styled.div<Props>`
    @media (max-width: 768px) {
        height: calc(100% - 50px - 50px - 30px);
        margin-top: 100px;
    }
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    height: calc(100% - 50px - 30px);
    width: 100%;
    overflow: hidden;
`