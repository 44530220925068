import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { useTransition } from '@react-spring/web';

import { State, Step } from '../../redux/types';

import { StyledConfigurator } from './configurator.styled';

import DesktopNavigation from '../../components/Navigation/DesktopNavigation/desktopNavigation';
import MobileNavigation from '../../components/Navigation/MobileNavigation/mobileNavigation';

import ProductPage from './StepPages/ProductPage/productPage';
import MaterialPage from './StepPages/MaterialPage/materialPage';
import ColorPage from './StepPages/ColorPage/colorPage';
import OptionsPage from './StepPages/OptionsPage/optionsPage';


interface Props {

}

export const Configurator: React.FC<Props> = (props: Props) => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const { step, lastDisplayedStep } = useSelector((state: State) => state.configurator);

    const pageTransitions = useTransition(step, {
        from: isMobile ? {} : step >= lastDisplayedStep ? 
            { opacity: 0, transform: 'translate3d(50%,0,0) scale(0.5)' } : 
            { opacity: 0, transform: 'translate3d(-50%,0,0) scale(0.5)' },

        enter: isMobile ? {} : { opacity: 1, transform: 'translate3d(0%,0,0) scale(1)' },

        leave: isMobile ? {} : step >= lastDisplayedStep ? 
            { opacity: 0, transform: 'translate3d(-50%,0,0) scale(0.5)' } : 
            { opacity: 0, transform: 'translate3d(50%,0,0) scale(0.5)' },
    })

    const desktopNavTransitions = useTransition(!isMobile, {
        from: { transform: 'translate3d(-200%,0,0)' },
        enter: { transform: 'translate3d(0%,0,0)' },
        leave: { transform: 'translate3d(-200%,0,0)' }
    })

    const mobileNavTransitions = useTransition(isMobile, {
        from: { transform: 'translate3d(0,-200%,0)' },
        enter: { transform: 'translate3d(0,0%,0)' },
        leave: { transform: 'translate3d(0,-200%,0)' }
    })

    return (
        <StyledConfigurator>
            {mobileNavTransitions((style, visible) => visible ? <MobileNavigation style={style}/> : null)}
            {desktopNavTransitions((style, visible) => visible ? <DesktopNavigation style={style}/> : null)}
            {pageTransitions((style, page) => {
                switch (page) {
                    case Step.product:
                        return <ProductPage style={style} />
                    case Step.material:
                        return <MaterialPage style={style} />
                    case Step.color:
                        return <ColorPage style={style} />
                    case Step.options:
                        return <OptionsPage style={style} />
                }
            })}
        </StyledConfigurator>
    )
}

export default Configurator