import styled from "styled-components";
import theme from "../../../../themes/default.theme";
import { lighten } from "polished";

interface Props {
    isCurrent: boolean;
    isActive: boolean;
}

export const StyledStepIcon = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    font-family: SemiBold;
    user-select: none;
    pointer-events: none;
`

export const StyledStepTitle = styled.p`
    @media (max-height: 768px) {
        display: none;
    }
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: SemiBold;
    user-select: none;
    pointer-events: none;
`

export const StyledStep = styled.div<Props>`
    width: 43px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: start;
    text-align: center;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    border-radius: 30px;
    border: 2px solid ${({isCurrent}) => isCurrent ? theme.colors.primary : theme.colors.accent};
    background-color: ${({isCurrent}) => isCurrent ? theme.colors.primary : theme.colors.accent};
    ${StyledStepIcon} {
        background-color: ${({isCurrent, isActive}) => isCurrent ? theme.colors.accent : isActive ? theme.colors.primary : theme.colors.deactivated};
        color: ${({isCurrent}) => isCurrent ? theme.colors.primary : theme.colors.accent};
    }
    ${StyledStepTitle} {
        color: ${({isCurrent, isActive}) => isCurrent ? theme.colors.accent : isActive ? theme.colors.primary : theme.colors.deactivated};
        display: ${({isCurrent}) => isCurrent ? 'block' : ''};
    }
    &:hover {
        @media (max-height: 768px) {
            width: ${({isCurrent}) => !isCurrent ? 'auto' : ''};
            height: ${({isCurrent}) => !isCurrent ? '43px' : ''};
            flex-direction: ${({isCurrent}) => !isCurrent ? 'row' : ''};
            padding-top: ${({isCurrent}) => !isCurrent ? '0px' : ''};
            padding-bottom: ${({isCurrent}) => !isCurrent ? '0px' : ''};
            background-color: ${({isCurrent}) => !isCurrent ? theme.colors.primary : ''};
            margin-top: ${({isCurrent}) => !isCurrent ? '0' : ''};
            margin-left: ${({isCurrent}) => !isCurrent ? '6.5px' : ''};
            margin-bottom: ${({isCurrent}) => !isCurrent ? '0' : ''};
            padding-left: ${({isCurrent}) => !isCurrent ? '6.5px' : ''};
            padding-right: ${({isCurrent}) => !isCurrent ? '6.5px' : ''};
            align-self: ${({isCurrent}) => !isCurrent ? 'flex-start' : ''};
            box-shadow: ${({isCurrent}) => !isCurrent ? `0 5px 15px ${theme.colors.shadow}` : ''};
            border: ${({isCurrent, isActive}) => !isCurrent ? isActive ? `2px solid ${lighten(0.05, theme.colors.accent)}` : `2px solid ${theme.colors.primary}` : ''};
            ${StyledStepIcon} {
                background-color: ${({isCurrent, isActive}) => !isCurrent ? isActive ? theme.colors.accent : theme.colors.deactivated : ''};
                color: ${({isCurrent}) => !isCurrent ? theme.colors.primary: ''};
            }
            ${StyledStepTitle} {
                color: ${({isCurrent, isActive}) => !isCurrent ? isActive ? theme.colors.accent : theme.colors.deactivated : ''};
                writing-mode: ${({isCurrent}) => !isCurrent ? 'horizontal-tb' : ''};
                transform: ${({isCurrent}) => !isCurrent ? 'rotate(0deg)' : ''};
                margin-top: ${({isCurrent}) => !isCurrent ? '0px' : ''};
                margin-left: ${({isCurrent}) => !isCurrent ? '10px' : ''};
                margin-right: ${({isCurrent}) => !isCurrent ? '10px' : ''};
                margin-bottom: ${({isCurrent}) => !isCurrent ? '0px' : ''};
            }
        }
        border: ${({isCurrent, isActive}) => !isCurrent ? isActive ? `2px solid ${theme.colors.primary}` : `2px solid ${theme.colors.accent}` : ''};
        ${StyledStepTitle} {
            display: block;
        }
        transition: width 0.2s ease-in-out;
        overflow: hidden;
    }
`