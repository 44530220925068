import { DefaultTheme } from 'styled-components';
import { rgba } from 'polished';

const theme: DefaultTheme = {
    colors: {
        border: '#d4dadf',
        accent: '#ffcc00',
        primary: '#fff',
        secondary: '#e0e0e0',
        deactivated: '#99927a',
        controls: '#fff',
        shadow: rgba(0, 0, 0, 0.4)
    }
}

export default theme