import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { State, ConfigObject, ConfigProduct } from '../../redux/types';
import { setSize, setGateOption, setGateExtras, setPostOption, setFenceLength, setFenceExtras, setCurrentConfig, resetConfigurator, storeConfig, storeConfigToDB } from '../../redux/actions';

import { StyledSummary, SummaryTitle, ButtonGroup, SummaryContent } from './summary.styled'

import api from '../../api/api';
import { ProductInfo  } from '../../api/types';

import { getProductId, getPostPid } from '../../helpers/builders'
import { calcElementCount } from '../../helpers/calc'

import { productList, Product, GateOption, GateExtras, PostOption, FenceExtras, ColorNames, ProductVariantNames, FencePostMap } from '../../common/products';

import SelectionGroup, { SelectionOption } from '../SelectionGroup/selectionGroup';
import Input from '../Input/input';
import Button from '../Button/button';
import OfferTable from '../OfferTable/offerTable';

type Props = {

}

const Summary: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();
    
    const { product, color, material, variant, size, gateOption, gateExtras, postOption, fenceLength, fenceExtras, configList } = useSelector((state: State) => state.configurator);

    useEffect(() => {
        (async function () {
            let products: ConfigProduct[] = [];
            
            const pid = getProductId(product!!, material!!, size!!, variant!!, gateOption!!);
            const pInfo: ProductInfo = await api.getProductInfo(pid);

            let projectObject: ConfigProduct;
            
            if (product === Product.fence) {
                const elementCount = calcElementCount(fenceLength, size?.width!!);

                projectObject = {
                    quantity: elementCount,
                    name: pInfo.name,
                    price: parseFloat(pInfo.price),
                }

                products.push(projectObject);

                let pObjIndex = Object.keys(FencePostMap[postOption!!][material!!]).indexOf(size!!.height!!.toString());

                if (pObjIndex < Object.keys(FencePostMap[postOption!!][material!!]).length - 1 && fenceExtras === FenceExtras.bsb) {
                    pObjIndex++;
                }

                const postLength = Object.entries(FencePostMap[postOption!!][material!!])[pObjIndex][1];

                const postInfo = await api.getProductInfo(getPostPid(postLength, postOption!!));

                const postObject: ConfigProduct = {
                    quantity: elementCount + 1,
                    name: postInfo.name,
                    price: parseFloat(postInfo.price),
                }

                products.push(postObject);

                if (fenceExtras !== null) {
                    const bsbInfo = await api.getProductInfo(fenceExtras);
                    
                    const extraObject: ConfigProduct = {
                        quantity: elementCount,
                        name: bsbInfo.name,
                        price: parseFloat(bsbInfo.price),
                    }

                    products.push(extraObject);
                }
            } else {
                const nameParts = pInfo.name.split(' ');
                const newName = nameParts.reduce((acc, cur, index) => {
                    if (index === 0) {
                        return cur;
                    } if (index === nameParts.length - 2) {
                        return acc + ' ' + cur + ' ' + ProductVariantNames[variant!!].replace(' mm', '') + ' ' + (gateOption === GateOption.manual ? 'manuell' : 'automatisch');
                    } else {
                        return acc + ' ' + cur;
                    }
                }, '');

                projectObject = {
                    quantity: 1,
                    name: newName,
                    price: parseFloat(pInfo.price),
                }

                products.push(projectObject);

                if (gateExtras !== null) {
                    const hsInfo = await api.getProductInfo(gateExtras);

                    const extraObject: ConfigProduct = {
                        quantity: 1,
                        name: hsInfo.name,
                        price: parseFloat(hsInfo.price),
                    }

                    products.push(extraObject);
                }
            }

            const config: ConfigObject = {
                products,
                color: ColorNames[color!!],
                totalPrice: parseFloat(products.reduce((acc, cur) => acc + (cur.price * cur.quantity), 0).toFixed(2)),
                imageURL: productList[product!!].images[variant!!],
                length: product === Product.fence ? fenceLength : undefined
            }

            dispatch(setCurrentConfig(config));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, material, color, variant, size, gateOption, fenceExtras, fenceLength, gateExtras, postOption]);

    const heightOptions: SelectionOption[] = productList[product!!].materials[material!!].sizes.heights.map((height) => {
        return {
            id: height,
            label: `${height} mm`
        }
    });

    const widthOptions: SelectionOption[] = productList[product!!].materials[material!!].sizes.widths.map((width) => {
        return {
            id: width,
            label: `${width} mm`
        }
    });

    const gateOptions: SelectionOption[] = [
        {
            id: GateOption.manual,
            label: "manuell"
        },
        {
            id: GateOption.automatic,
            label: "automatisch"
        }
    ]

    const gateExtrasOptions: SelectionOption[] = [
        {
            id: null,
            label: "ohne"
        },
        {
            id: GateExtras.hs2,
            label: "2 Kanal"
        },
        {
            id: GateExtras.hs4,
            label: "4 Kanal"
        }
    ]

    const postOptions: SelectionOption[] = [
        {
            id: PostOption.concreted,
            label: "einbetoniert"
        },
        {
            id: PostOption.dowelPlate,
            label: "Dübelplatte"
        }
    ]

    const fenceExtrasOptions: SelectionOption[] = [
        {
            id: null,
            label: "ohne"
        },
        {
            id: FenceExtras.bsb,
            label: "mit"
        }
    ]

    const title = productList[product!!].name;
    const femList = ["Gehtür", "Anschlussgehtür"]

    return (
        <StyledSummary id="summary">
            <SummaryTitle>{femList.includes(title) ? "Ihre" : "Ihr"} {title}</SummaryTitle>
            <SummaryContent>
                {product === Product.fence && <Input title='Zaunlänge' state={fenceLength} unit={"m"} max={5} type='number' setState={(value: string) => dispatch(setFenceLength(isNaN(parseInt(value)) ? 1 : parseInt(value)))} />}
                <SelectionGroup title={"Höhe:"} options={heightOptions} state={size?.height} onClick={(height: number) => {dispatch(setSize({width: size?.width!!, height}))}} />
                <SelectionGroup title={"Breite:"} options={widthOptions} state={size?.width} onClick={(width: number) => {dispatch(setSize({width, height: size?.height!!}))}} />
                {product !== Product.fence && <SelectionGroup title={"Ausführung:"} options={gateOptions} state={gateOption} onClick={(gateOption: GateOption) => {dispatch(setGateOption(gateOption))}} />}
                {product !== Product.fence && gateOption === GateOption.automatic && <SelectionGroup title={"Handschalter:"} options={gateExtrasOptions} state={gateExtras} onClick={(gateExtra: GateExtras) => {dispatch(setGateExtras(gateExtra))}} />}
                {product === Product.fence && <SelectionGroup title={"Zaunpfosten:"} options={postOptions} state={postOption} onClick={(postOption: PostOption) => {dispatch(setPostOption(postOption))}} />}
                {product === Product.fence && <SelectionGroup title={"Betonsockelbrett:"} options={fenceExtrasOptions} state={fenceExtras} onClick={(fenceExtra: FenceExtras) => {dispatch(setFenceExtras(fenceExtra))}} />}
                <OfferTable/>
            </SummaryContent>
            <ButtonGroup>
                <Button onClick={() => {dispatch(storeConfig()); dispatch(resetConfigurator())}}>+ Konfiguration</Button>
                <Button onClick={() => {dispatch(storeConfig()); dispatch(storeConfigToDB()); dispatch(resetConfigurator())}}>{configList.length > 0 ? 'Konfigurationen speichern' : 'Konfiguration speichern'}</Button>
            </ButtonGroup>
        </StyledSummary>
    )
}

export default Summary