import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { animated } from '@react-spring/web';

import { setMaterial } from '../../../../redux/actions';
import { State } from '../../../../redux/types';

import Card from '../../../../components/Card/card';

import { StyledPage, StyledSelection, StyledTitle } from './materialPage.styled';

import { productList, Material, MaterialProductVariantMap, ProductVariants, MaterialNames } from '../../../../common/products';


type Props = {
    style: any
}

const MaterialPage: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();
    const { product, material } = useSelector((state: State) => state.configurator);

    const isCardSelected = (id: string) => {
        if (material) {
            return id === material;
        }
        return true;
    }

    const getFirstVariant = (material: Material): ProductVariants => {
        return Object.entries(MaterialProductVariantMap[material])[0][1];
    }

    return (
        <StyledPage as={animated.div} style={props.style}>
            <StyledTitle>Wählen Sie das Material für {productList[product!!].pronoun} {productList[product!!].name}:</StyledTitle>
            <StyledSelection>
                {Object.entries(Material).map(([key, value]) => (
                    <Card image={productList[product!!].images[getFirstVariant(value)]}
                          material={value}
                          text={MaterialNames[value]} 
                          onClick={() => dispatch(setMaterial(value))} 
                          key={key} 
                          selected={isCardSelected(value)} />
                ))}
            </StyledSelection>
        </StyledPage>
    )
}

export default MaterialPage