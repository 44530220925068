import { configureStore, applyMiddleware, combineReducers, Reducer, Store } from '@reduxjs/toolkit';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import { getPersistConfig } from 'redux-deep-persist';
// import storage from 'redux-persist/lib/storage';
import  storageSession from 'redux-persist/lib/storage/session';

import { configuratorReducer } from './reducer';

const rootReducer: Reducer = combineReducers({
    configurator: configuratorReducer
});

const rootPersistConfig: PersistConfig<any> = getPersistConfig({
    key: 'root',
    storage: storageSession,
    blacklist: [],
    rootReducer: rootReducer
});

const persistedReducer: Reducer = persistReducer(rootPersistConfig, rootReducer);

export const store: Store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [applyMiddleware(ReduxThunk)]
});

export const persistor = persistStore(store);