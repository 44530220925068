// fences
import FenceS32 from '../assets/productImages/fence/za_s32.png';
import FenceS65 from '../assets/productImages/fence/za_s65.png';
import FenceS110 from '../assets/productImages/fence/za_s110.png';
import FenceQ32 from '../assets/productImages/fence/za_q32.png';
import FenceQ65 from '../assets/productImages/fence/za_q65.png';
import FenceQ100 from '../assets/productImages/fence/za_q100.png';
import FenceQ110 from '../assets/productImages/fence/za_q110.png';
import FenceQ150 from '../assets/productImages/fence/za_q150.png';
import FenceQ200 from '../assets/productImages/fence/za_q200.png';
import FenceGBO from '../assets/productImages/fence/za_gbo.png';
import FenceGB1 from '../assets/productImages/fence/za_gb1.png';
import FenceLB from '../assets/productImages/fence/za_lb.png';

// sliding gates
import SlidingGateS32 from '../assets/productImages/slidingGate/st_s32.png';
import SlidingGateS65 from '../assets/productImages/slidingGate/st_s65.png';
import SlidingGateS110 from '../assets/productImages/slidingGate/st_s110.png';
import SlidingGateQ32 from '../assets/productImages/slidingGate/st_q32.png';
import SlidingGateQ65 from '../assets/productImages/slidingGate/st_q65.png';
import SlidingGateQ100 from '../assets/productImages/slidingGate/st_q100.png';
import SlidingGateQ110 from '../assets/productImages/slidingGate/st_q110.png';
import SlidingGateQ150 from '../assets/productImages/slidingGate/st_q150.png';
import SlidingGateQ200 from '../assets/productImages/slidingGate/st_q200.png';
import SlidingGateGBO from '../assets/productImages/slidingGate/st_gbo.png';
import SlidingGateGB1 from '../assets/productImages/slidingGate/st_gb1.png';
import SlidingGateLB from '../assets/productImages/slidingGate/st_lb.png';

// gates
import GateS32 from '../assets/productImages/gate/dt_s32.png';
import GateS65 from '../assets/productImages/gate/dt_s65.png';
import GateS110 from '../assets/productImages/gate/dt_s110.png';
import GateQ32 from '../assets/productImages/gate/dt_q32.png';
import GateQ65 from '../assets/productImages/gate/dt_q65.png';
import GateQ100 from '../assets/productImages/gate/dt_q100.png';
import GateQ110 from '../assets/productImages/gate/dt_q110.png';
import GateQ150 from '../assets/productImages/gate/dt_q150.png';
import GateQ200 from '../assets/productImages/gate/dt_q200.png';
import GateGBO from '../assets/productImages/gate/dt_gbo.png';
import GateGB1 from '../assets/productImages/gate/dt_gb1.png';
import GateLB from '../assets/productImages/gate/dt_lb.png';

// doors
import DoorS32 from '../assets/productImages/door/gt_s32.png';
import DoorS65 from '../assets/productImages/door/gt_s65.png';
import DoorS110 from '../assets/productImages/door/gt_s110.png';
import DoorQ32 from '../assets/productImages/door/gt_q32.png';
import DoorQ65 from '../assets/productImages/door/gt_q65.png';
import DoorQ100 from '../assets/productImages/door/gt_q100.png';
import DoorQ110 from '../assets/productImages/door/gt_q110.png';
import DoorQ150 from '../assets/productImages/door/gt_q150.png';
import DoorQ200 from '../assets/productImages/door/gt_q200.png';
import DoorGBO from '../assets/productImages/door/gt_gbo.png';
import DoorGB1 from '../assets/productImages/door/gt_gb1.png';
import DoorLB from '../assets/productImages/door/gt_lb.png';

// connecting doors
import ConnectingDoorS32 from '../assets/productImages/connectingDoor/agt_s32.png';
import ConnectingDoorS65 from '../assets/productImages/connectingDoor/agt_s65.png';
import ConnectingDoorS110 from '../assets/productImages/connectingDoor/agt_s110.png';
import ConnectingDoorQ32 from '../assets/productImages/connectingDoor/agt_q32.png';
import ConnectingDoorQ65 from '../assets/productImages/connectingDoor/agt_q65.png';
import ConnectingDoorQ100 from '../assets/productImages/connectingDoor/agt_q100.png';
import ConnectingDoorQ110 from '../assets/productImages/connectingDoor/agt_q110.png';
import ConnectingDoorQ150 from '../assets/productImages/connectingDoor/agt_q150.png';
import ConnectingDoorQ200 from '../assets/productImages/connectingDoor/agt_q200.png';
import ConnectingDoorGBO from '../assets/productImages/connectingDoor/agt_gbo.png';
import ConnectingDoorGB1 from '../assets/productImages/connectingDoor/agt_gb1.png';
import ConnectingDoorLB from '../assets/productImages/connectingDoor/agt_lb.png';



export enum Product {
    'fence' = 'za',
    'slidingGate' = 'st',
    'gate' = 'dt',
    'door' = 'gt',
    'connectingDoor' = 'agt',
}

export enum Material {
    'aluminium' = 'a',
    'sheet' = 'b',
}

export const MaterialNames = {
    [Material.aluminium]: 'Aluminium',
    [Material.sheet]: 'Feinblech',
}

export enum Color {
    'anthracite' = 'a',
    'silver' = 's',
    'green' = 'g',
}

export const ColorNames = {
    [Color.anthracite]: 'Anthrazit',
    [Color.silver]: 'Silber',
    [Color.green]: 'Grün',
}

export const MaterialColorMap = {
    [Material.aluminium]: [Color.anthracite, Color.silver],
    [Material.sheet]: [Color.anthracite, Color.silver, Color.green],
}

export const enum ProductVariants {
    's32' = 's32',
    's65' = 's65',
    's110' = 's110',
    'q32' = 'q32',
    'q65' = 'q65',
    'q100' = 'q100',
    'q110' = 'q110',
    'q150' = 'q150',
    'q200' = 'q200',
    'gbo' = 'gbo',
    'gb1' = 'gb1',
    'lb' = 'lb'
}

export const ProductVariantNames = {
    [ProductVariants.s32]: 'Stäbe 32 mm',
    [ProductVariants.s65]: 'Stäbe 65 mm',
    [ProductVariants.s110]: 'Stäbe 110 mm',
    [ProductVariants.q32]: 'Querlatte 32 mm',
    [ProductVariants.q65]: 'Querlatte 65 mm',
    [ProductVariants.q100]: 'Querlatte 100 mm',
    [ProductVariants.q110]: 'Querlatte 110 mm',
    [ProductVariants.q150]: 'Querlatte 150 mm',
    [ProductVariants.q200]: 'Querlatte 200 mm',
    [ProductVariants.gbo]: 'Glattblech',
    [ProductVariants.gb1]: 'Glattblech 1 Lochblech',
    [ProductVariants.lb]: 'Lochblech',
}

export enum GateOption {
    'manual' = 'm',
    'automatic' = 'a',
}

export enum GateExtras {
    'hs2' = 'zb-hs-2',
    'hs4' = 'zb-hs-4',
}

export enum PostOption {
    'concreted' = 'eb',
    'dowelPlate' = 'gd',
}

export const FencePostMap = {
    [PostOption.concreted]: {
        [Material.aluminium]: {
            1000: 1500,
            1200: 2000,
            1400: 2200,
        },
        [Material.sheet]: {
            1015: 1500,
            1215: 2000,
            1415: 2200,
            1615: 2400,
            1815: 2800,
            2015: 2800,
        }
    },
    [PostOption.dowelPlate]: {
        [Material.aluminium]: {
            1000: 1700,
            1200: 1700,
            1400: 1700,
        },
        [Material.sheet]: {
            1015: 1700,
            1215: 1700,
            1415: 1700,
            1615: 1700,
            1815: 2100,
            2015: 2100,
        }
    }
}

export const enum FenceExtras {
    'bsb' = 'zb-bsb',
}

export const MaterialProductVariantMap = {
    [Material.aluminium]: [ProductVariants.s32, ProductVariants.s65, ProductVariants.s110, ProductVariants.q32, ProductVariants.q65, ProductVariants.q110],
    [Material.sheet]: [ProductVariants.q100, ProductVariants.q150, ProductVariants.q200, ProductVariants.gbo, ProductVariants.gb1, ProductVariants.lb],
}

export const productList = {
    [Product.fence]: {
        name: "Zaun",
        pronoun: "Ihren",
        materials: {
            [Material.aluminium]: {
                sizes: {
                    heights: [1000, 1200, 1400],
                    widths: [2000]
                }
            },
            [Material.sheet]: {
                sizes: {
                    heights: [1015, 1215, 1415, 1615, 1815, 2015],
                    widths: [2000]
                }
            }
        },
        images: {
            [ProductVariants.s32]: FenceS32,
            [ProductVariants.s65]: FenceS65,
            [ProductVariants.s110]: FenceS110,
            [ProductVariants.q32]: FenceQ32,
            [ProductVariants.q65]: FenceQ65,
            [ProductVariants.q100]: FenceQ100,
            [ProductVariants.q110]: FenceQ110,
            [ProductVariants.q150]: FenceQ150,
            [ProductVariants.q200]: FenceQ200,
            [ProductVariants.gbo]: FenceGBO,
            [ProductVariants.gb1]: FenceGB1,
            [ProductVariants.lb]: FenceLB,
        }
    },
    [Product.slidingGate]: {
        name: "Schiebetor",
        pronoun: "Ihr",
        materials: {
            [Material.aluminium]: {
                sizes: {
                    heights: [1000, 1200, 1400, 1600, 1800, 2000],
                    widths: [3000, 4000, 5000, 6000]
                }
            },
            [Material.sheet]: {
                sizes: {
                    heights: [1000, 1200, 1400, 1600, 1800, 2000],
                    widths: [3000, 4000, 5000, 6000]
                }
            }
        },
        images: {
            [ProductVariants.s32]: SlidingGateS32,
            [ProductVariants.s65]: SlidingGateS65,
            [ProductVariants.s110]: SlidingGateS110,
            [ProductVariants.q32]: SlidingGateQ32,
            [ProductVariants.q65]: SlidingGateQ65,
            [ProductVariants.q100]: SlidingGateQ100,
            [ProductVariants.q110]: SlidingGateQ110,
            [ProductVariants.q150]: SlidingGateQ150,
            [ProductVariants.q200]: SlidingGateQ200,
            [ProductVariants.gbo]: SlidingGateGBO,
            [ProductVariants.gb1]: SlidingGateGB1,
            [ProductVariants.lb]: SlidingGateLB,
        }
    },
    [Product.gate]: {
        name: "Drehtor",
        pronoun: "Ihr",
        materials: {
            [Material.aluminium]: {
                sizes: {
                    heights: [1000, 1200, 1400, 1600, 1800, 2000],
                    widths: [2340, 3340, 4340]
                }
            },
            [Material.sheet]: {
                sizes: {
                    heights: [1000, 1200, 1400, 1600, 1800, 2000],
                    widths: [2340, 3340, 4340]
                }
            }
        },
        images: {
            [ProductVariants.s32]: GateS32,
            [ProductVariants.s65]: GateS65,
            [ProductVariants.s110]: GateS110,
            [ProductVariants.q32]: GateQ32,
            [ProductVariants.q65]: GateQ65,
            [ProductVariants.q100]: GateQ100,
            [ProductVariants.q110]: GateQ110,
            [ProductVariants.q150]: GateQ150,
            [ProductVariants.q200]: GateQ200,
            [ProductVariants.gbo]: GateGBO,
            [ProductVariants.gb1]: GateGB1,
            [ProductVariants.lb]: GateLB,
        }
    },
    [Product.door]: {
        name: "Gehtür",
        pronoun: "Ihre",
        materials: {
            [Material.aluminium]: {
                sizes: {
                    heights: [1000, 1200, 1400, 1600, 1800, 2000],
                    widths: [1200]
                }
            },
            [Material.sheet]: {
                sizes: {
                    heights: [1000, 1200, 1400, 1600, 1800, 2000],
                    widths: [1200]
                }
            }
        },
        images: {
            [ProductVariants.s32]: DoorS32,
            [ProductVariants.s65]: DoorS65,
            [ProductVariants.s110]: DoorS110,
            [ProductVariants.q32]: DoorQ32,
            [ProductVariants.q65]: DoorQ65,
            [ProductVariants.q100]: DoorQ100,
            [ProductVariants.q110]: DoorQ110,
            [ProductVariants.q150]: DoorQ150,
            [ProductVariants.q200]: DoorQ200,
            [ProductVariants.gbo]: DoorGBO,
            [ProductVariants.gb1]: DoorGB1,
            [ProductVariants.lb]: DoorLB,
        }
    },
    [Product.connectingDoor]: {
        name: "Anschlussgehtür",
        pronoun: "Ihre",
        materials: {
            [Material.aluminium]: {
                sizes: {
                    heights: [1000, 1200, 1400, 1600, 1800, 2000],
                    widths: [1200]
                }
            },
            [Material.sheet]: {
                sizes: {
                    heights: [1000, 1200, 1400, 1600, 1800, 2000],
                    widths: [1200]
                }
            }
        },
        images: {
            [ProductVariants.s32]: ConnectingDoorS32,
            [ProductVariants.s65]: ConnectingDoorS65,
            [ProductVariants.s110]: ConnectingDoorS110,
            [ProductVariants.q32]: ConnectingDoorQ32,
            [ProductVariants.q65]: ConnectingDoorQ65,
            [ProductVariants.q100]: ConnectingDoorQ100,
            [ProductVariants.q110]: ConnectingDoorQ110,
            [ProductVariants.q150]: ConnectingDoorQ150,
            [ProductVariants.q200]: ConnectingDoorQ200,
            [ProductVariants.gbo]: ConnectingDoorGBO,
            [ProductVariants.gb1]: ConnectingDoorGB1,
            [ProductVariants.lb]: ConnectingDoorLB,
        }
    }
}