export function calcElementCount (fenceLength: number, elementLength: number): number {
const postWidth = 60;

    let count = 1;
    let res = fenceLength * 1000;
    res = res - elementLength - postWidth * 2;

    while (res > 0) {
        res = res - elementLength - postWidth;
        count++;
    }

    return count;
}