import { Reducer } from "redux";

import { ConfiguratorState, Action, Actions, Step, ConfigList } from "./types";

import { MaterialProductVariantMap, productList, GateOption, PostOption } from '../common/products';

import api from '../api/api';
import { createConfigPDF } from '../lib/pdfCreator'

import Logo from '../assets/logo2.png';

const configuratorInitialState: ConfiguratorState = {
    step: Step.product,
    lastCompletedStep: null,
    lastDisplayedStep: Step.product,
    mode: null,
    material: null,
    product: null,
    color: null,
    variant: null,
    gateOption: GateOption.manual,
    gateExtras: null,
    postOption: PostOption.concreted,
    fenceExtras: null,
    size: null,
    fenceLength: 10,
    currentConfig: null,
    configList: []
}

export const configuratorReducer: Reducer = (state: ConfiguratorState = configuratorInitialState, action: Action): ConfiguratorState => {
    switch (action.type) {
        case Actions.gotoStep:
            return {
                ...state,
                lastDisplayedStep: state.step,
                step: action.payload?.step!!
            }
        case Actions.goBack:
            return {
                ...state,
                lastDisplayedStep: state.step,
                step: state.step > 1 ? state.step - 1 : 1
            }
        case Actions.setProduct:
            return {
                ...state,
                lastDisplayedStep: state.step,
                step: Step.material,
                lastCompletedStep: Step.product,
                mode: action.payload?.mode!!,
                product: action.payload?.product!!,
                material: configuratorInitialState.material,
                color: configuratorInitialState.color,
                variant: configuratorInitialState.variant,
                gateOption: configuratorInitialState.gateOption,
                gateExtras: configuratorInitialState.gateExtras,
                postOption: configuratorInitialState.postOption,
                fenceExtras: configuratorInitialState.fenceExtras,
                size: configuratorInitialState.size,
                fenceLength: configuratorInitialState.fenceLength,
                currentConfig: null
            }
        case Actions.setMaterial:
            return {
                ...state,
                lastDisplayedStep: state.step,
                step: Step.color,
                lastCompletedStep: Step.material,
                material: action.payload?.material!!,
                variant: MaterialProductVariantMap[action.payload?.material!!][0],
                gateOption: configuratorInitialState.gateOption,
                gateExtras: configuratorInitialState.gateExtras,
                postOption: configuratorInitialState.postOption,
                fenceExtras: configuratorInitialState.fenceExtras,
                size: { width: productList[state.product!!].materials[action.payload?.material!!].sizes.widths[0], height: productList[state.product!!].materials[action.payload?.material!!].sizes.heights[0] },
                color: configuratorInitialState.color,
                fenceLength: configuratorInitialState.fenceLength,
                currentConfig: null
            }
        case Actions.setColor:
            return {
                ...state,
                lastDisplayedStep: state.step,
                step: Step.options,
                lastCompletedStep: Step.color,
                color: action.payload?.color!!,
                fenceLength: configuratorInitialState.fenceLength,
                variant: MaterialProductVariantMap[state.material!!][0],
                gateOption: configuratorInitialState.gateOption,
                gateExtras: configuratorInitialState.gateExtras,
                postOption: configuratorInitialState.postOption,
                fenceExtras: configuratorInitialState.fenceExtras,
                size: { width: productList[state.product!!].materials[state.material!!].sizes.widths[0], height: productList[state.product!!].materials[state.material!!].sizes.heights[0] },
                currentConfig: null
            }
        case Actions.setVariant:
            return {
                ...state,
                variant: action.payload?.variant!!
            }
        case Actions.setSize:
            return {
                ...state,
                size: action.payload?.size!!
            }
        case Actions.setLength:
            return {
                ...state,
                fenceLength: action.payload?.fenceLength!!
            }
        case Actions.setGateOption:
            return {
                ...state,
                gateOption: action.payload?.gateOption!!,
                gateExtras: configuratorInitialState.gateExtras
            }
        case Actions.setGateExtras:
            return {
                ...state,
                gateExtras: action.payload?.gateExtras!!
            }
        case Actions.setPostOption:
            return {
                ...state,
                postOption: action.payload?.postOption!!
            }
        case Actions.setFenceExtras:
            return {
                ...state,
                fenceExtras: action.payload?.fenceExtras!!
            }
        case Actions.setCurrentConfig:
            return {
                ...state,
                currentConfig: action.payload?.currentConfig!!
            }
        case Actions.storeConfig:
            return {
                ...state,
                configList: [...state.configList, state.currentConfig!!]
            }
        case Actions.storeConfigToDB:
            api.saveConfig(state.configList).then((res) => {
                const pdfConfig: ConfigList = {
                    configs: state.configList,
                    dbId: res.cid!!,
                    logoURL: Logo
                }
                createConfigPDF(pdfConfig);
            });
            return {
                ...state
            }
        case Actions.resetConfigurator:
            return {
                ...configuratorInitialState,
                configList: state.configList
            }
        default:
            return state;
    }
}