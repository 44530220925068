import axios, { AxiosResponse } from 'axios';

import { ProductInfo, DBConfigObject, SaveConfigResponse } from './types';

import { ConfigList, ConfigObject } from '../redux/types'


function getApiURI(): string {
    const apiPath = '/apiv1';
    const localPort = 8080;

    const { origin, hostname, protocol } = window.location;

    if (protocol !== 'https:') {
        return `${protocol}//${hostname}:${localPort}${apiPath}`;
    }
    return `${origin}${apiPath}`;
};

const api = axios.create({
    baseURL: getApiURI(),
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});


function generateConfigId(): string {
    // gernerate a random config id
    // 8 characters long, alphanumeric
    // first character is a number
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < 8; i++) {
        let charset = 10;
        if (i > 0) {
            charset = chars.length;
        }
        result += chars.charAt(Math.floor(Math.random() * charset));
    }
    return result;
}

class ApiService {
    async getProductInfo(pid: string): Promise<ProductInfo> {
        try {
            const response = await api.get(`/p?pid=${pid}`);
            return response.data;
        } catch (error: any) {
            return error.response.data;
        }
    }

    async saveConfig(config: ConfigObject[]): Promise<SaveConfigResponse> {
        try {
            const configID = generateConfigId();
            const configObj: DBConfigObject = {
                cid: configID,
                tempcontent: JSON.stringify(config)
            }
            const response = await api.post('/c', configObj);
            if (response.data.error) {
                return {
                    error: response.data.error
                }
            }
            return {
                cid: configID,
            }
        } catch (error: any) {
            return {
                error: error.response.data.error
            }
        }
    }

    async getConfig(cid: string): Promise<AxiosResponse<any, any>> {
        try {
            const response = await api.get(`/c?cid=${cid}`);
            if (response.status === 200) {
                const config: ConfigList = JSON.parse(response.data.tempcontent);
                response.data = config;
            }
            return response;
        } catch (error: any) {
            return error.response;
        }
    }
}

const apiService = new ApiService();

export default apiService;