import styled from "styled-components";
import theme from "../../../themes/default.theme";

interface Props {

}

export const StyledDesktopNavigation = styled.div<Props>`
    position: fixed;
    top: 0;
    left: 0;
    height: calc(100vh - 50px - 40px - 30px);
    width: 60px;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    row-gap: 10px;
    z-index: 100;
`

export const StyledDesktopNavBar = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    background-color: ${theme.colors.accent};
    border-radius: 30px;
    box-shadow: 0 5px 15px ${theme.colors.shadow};
    padding-top: 6.5px;
    padding-bottom: 6.5px;
`

interface BackButtonProps {
    active: boolean;
}

export const StyledDesktopBackButton = styled.div<BackButtonProps>`
    width: calc(100% - 4px);
    height: calc(60px - 4px);
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: ${({active}) => active ? theme.colors.accent : theme.colors.primary};
    box-shadow: 0 5px 15px ${theme.colors.shadow};
    border: 2px solid ${({active}) => active ? theme.colors.accent : theme.colors.deactivated};
    svg {
        color: ${({active}) => active ? theme.colors.primary : theme.colors.deactivated};
        height: 25px;
        width: 25px;
    }
    &:hover {
        background-color: ${({active}) => active ? theme.colors.primary : ''};
        svg {
            color: ${({active}) => active ? theme.colors.accent : ''};
        }
    }
`