import { Step } from '../../redux/types';

export type NavStepType = {
    id: number;
    title: string;
    step: Step;
};

export const Steps: NavStepType[] = [
    {
        id: 1,
        title: 'Produkt',
        step: Step.product
    },
    {
        id: 2,
        title: 'Material',
        step: Step.material
    },
    {
        id: 3,
        title: 'Farbe',
        step: Step.color
    },
    {
        id: 4,
        title: 'Varianten',
        step: Step.options
    },
];

export default Steps;