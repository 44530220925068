import styled from "styled-components";

import StyledStepPage from '../stepPage.styled';

interface Props {

}

export const StyledPage = styled(StyledStepPage.StyledPage)<Props>`
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    @media (min-width: 768px) {
        padding-right: 0px;
        width: calc(100vw - 100px);
    }
`

export const StyledContent = styled.div`
    @media (max-width: 768px) {
        width: 100%;
        height: fit-content;
        overflow: initial;
        padding-top: 30px;
        padding-right: 0px;
    }
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 500px;
    overflow-y overlay;
`

export const StyledSummaryContainer = styled.div`
    @media (max-width: 768px) {
        width: 100%;
        height: fit-content;
        padding: 0px;
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
    }
    position:fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: calc(100% - 40px);
    width: calc(500px - 40px);
`

export const StyledTitle = styled(StyledStepPage.StyledTitle)`
    height: auto
`

export const StyledSelection = styled(StyledStepPage.StyledSelection)`
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
`