import styled from "styled-components";
import theme from "../../themes/default.theme";

interface Props {

}

export const StyledPageFooter = styled.div<Props>`
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: left;
    height: 30px;
    width: 100vw;
    background-color: ${theme.colors.accent};
    box-shadow: 0 -1px 15px ${theme.colors.shadow};
`

export const StyledFooterText = styled.p`
    font-family: Regular;
    font-size: 0.8rem;
    user-select: text;
    pointer-events: all;
`