import React, { useEffect, useState, ChangeEvent } from 'react'

import { StyledInput, StyledInputContainer, StyledInputLabel, StyledInputUnitLabel } from './input.styled';

interface Props {
    title?: string;
    state: any;
    unit?: string;
    setState: (value: string) => void;
    placeholder?: string;
    max?: number;
    type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
    regex?: RegExp;
    onEnter?: () => void;
}


export const Input: React.FC<Props> = (props: Props) => {
    const [value, setValue] = useState<string>("");

    const changeEventHandler = (event: ChangeEvent<HTMLInputElement>) => {
        let val = event.target.value;
        if (props.type === "number") {
            val = val.replace(/[^0-9]/g, "");
        }
        if (props.max && val.length > props.max) {
            val = val.slice(0, props.max);
        }
        if (props.regex) {
            if (!props.regex.test(val) && val.length > 0) {
                val = value;
            }
        }
        setValue(val);
        props.setState(val)
    }

    const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            if (props.onEnter) {
                event.preventDefault();
                props.onEnter();
            }
        }
    }

    useEffect(() => {
        setValue(props.state);
    }, [props.state])

    // max int value with props.max digits (e.g. 9999 for max=4)
    const max = props.max ? Math.pow(10, props.max) - 1 : undefined;

    return (
        <StyledInputContainer>
            {props.title && <StyledInputLabel>{props.title}:</StyledInputLabel>}
            <StyledInput>
                <input type={props.type} maxLength={props.max} max={max} value={value} placeholder={props.placeholder} onChange={changeEventHandler} onKeyDown={keyDownHandler}></input>
                {props.unit && <StyledInputUnitLabel>{props.unit}</StyledInputUnitLabel>}
            </StyledInput>
        </StyledInputContainer>
    )
}

export default Input
