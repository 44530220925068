import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { animated } from '@react-spring/web';
import ReactCardFlip from 'react-card-flip';

import { setProduct } from '../../../../redux/actions';
import { State, ConfigList } from '../../../../redux/types';

import Card from '../../../../components/Card/card';
import SelectionCard , {Selection} from '../../../../components/SelectionCard/selectionCard';
import Input from '../../../../components/Input/input';
import Button from '../../../../components/Button/button';

import { StyledPage, StyledSelection, StyledTitle, QueryConfigContainer } from './productPage.styled';

import { productList, Product } from '../../../../common/products';

import api from '../../../../api/api';
import { createConfigPDF } from '../../../../lib/pdfCreator';

import Logo from '../../../../assets/logo2.png';

type Props = {
    style: any
}

const ProductPage: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();
    const [configId, setConfigId] = useState('');

    const [flipped, setFlipped] = useState(false);

    const { product } = useSelector((state: State) => state.configurator);

    const isCardSelected = (id: string) => {
        if (product) {
            return id === product;
        }
        return true;
    }

    const isSelectionSelected = () => {
        if (product) {
            return product !== Product.fence;
        }
        return true;
    }

    const selectionsNew: (Selection | undefined )[] = Object.entries(productList).filter(([key, value]) => key !== Product.fence).map(([key, value]) => {
        return {
            id: key,
            image: Object.entries(value.images)[0][1],
            text: value.name,
            onClick: () => dispatch(setProduct(key as Product))
        }
    })

    const regex = new RegExp('^[0-9][a-zA-Z0-9]{7}$');

    const queryConfig = async () => {
        if (!regex.test(configId)) {
            return;
        }
        const res = await api.getConfig(configId);
        if (res.status === 200) {
            const configList: ConfigList = {
                configs: res.data,
                dbId: configId,
                logoURL: Logo
            }
            createConfigPDF(configList);
        }
    }


    return (
        <StyledPage as={animated.div} style={props.style} >
            <StyledTitle>Wählen Sie aus, was Sie konfigurieren möchten:</StyledTitle>
            <StyledSelection>
                <Card image={Object.entries(productList[Product.fence].images)[0][1]} text={productList[Product.fence].name} onClick={() => dispatch(setProduct(Product.fence))} slideOutText='Unsere Zaunpaneele sind mit zwölf verschiedenen Füllungen und in sechs verschiedenen Höhen vorhanden.' selected={isCardSelected(Product.fence)} />
                <ReactCardFlip isFlipped={flipped} flipDirection='horizontal' infinite={true}>
                    <Card image={Object.entries(productList[Product.slidingGate].images)[0][1]} onClick={() => setFlipped(true)} text='Tor oder Tür' slideOutText='Unser Tor-Konfigurator bietet Ihnen die Möglichkeit Ihr perfektes Gartentor zu generieren.' selected={isSelectionSelected()} />
                    <SelectionCard text='Tor oder Tür' back={() => setFlipped(false)} selections={selectionsNew} />
                </ReactCardFlip>
            </StyledSelection>
            <QueryConfigContainer>
                <Input state={configId} setState={setConfigId} placeholder='ID' max={8} regex={/^[0-9][a-zA-Z0-9]{0,7}$/g} onEnter={queryConfig} />
                <Button onClick={queryConfig} disabled={!regex.test(configId)}>Konfiguration laden</Button>
            </QueryConfigContainer>
        </StyledPage>
    )
}

export default ProductPage