import styled from "styled-components";

import StyledStepPage from '../stepPage.styled';

import { StyledInputContainer, StyledInput } from '../../../../components/Input/input.styled';

interface Props {

}

export const StyledPage = styled(StyledStepPage.StyledPage)<Props>`

`

export const StyledTitle = styled(StyledStepPage.StyledTitle)`
    @media (max-width: 768px) {
        height: 10%;
    }
    @media (max-height: 768px) {
        height: 15%;
    }
    @media (max-height: 800px) {
        height: 20%;
    }
    height: 25%;
`

export const StyledSelection = styled(StyledStepPage.StyledSelection)`
    @media (max-width: 768px) {
        min-height: calc(80% - 20px);
    }
    @media (max-height: 768px) {
        min-height: calc(75% - 20px);
    }
    @media (max-height: 800px) {
        min-height: calc(70% - 20px);
    }
    min-height: 65%;
    height: fit-content;
`

export const QueryConfigContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 10%;
    width: 100%;
    flex-shrink: 0;
    ${StyledInputContainer} {
        margin: 0;
        padding: 0;
        ${StyledInput} {
            margin: 0;
        }
    }
`