import React from 'react'
import { useSelector } from 'react-redux';

import { State } from '../../redux/types';

import { StyledOfferTable, OfferTableContainer, OfferTableHeader, OfferTableBody, OfferTableRow, OfferTableItem } from './offerTable.styled'


type Props = {

}

const OfferTable = (props: Props) => {
    const { currentConfig } = useSelector((state: State) => state.configurator);

    const moneyStringOptions = {
        style: 'currency',
        currency: 'EUR',
    }

    return (
        <StyledOfferTable>
            <OfferTableContainer>
                <OfferTableHeader>
                    <OfferTableRow>
                        <OfferTableItem></OfferTableItem>
                        <OfferTableItem>Produkt</OfferTableItem>
                        <OfferTableItem>Preis</OfferTableItem>
                    </OfferTableRow>
                </OfferTableHeader>
                <OfferTableBody>
                    {currentConfig?.products.map((product, index) => (
                        <OfferTableRow key={index}>
                            <OfferTableItem>{product.quantity}</OfferTableItem>
                            <OfferTableItem>{product.name}</OfferTableItem>
                            <OfferTableItem>{product.price.toLocaleString('de-DE', moneyStringOptions)}</OfferTableItem>
                        </OfferTableRow>
                    ))}
                    <OfferTableRow>
                        <OfferTableItem></OfferTableItem>
                        <OfferTableItem></OfferTableItem>
                        <OfferTableItem>{currentConfig?.totalPrice.toLocaleString('de-DE', moneyStringOptions)}</OfferTableItem>
                    </OfferTableRow>
                </OfferTableBody>
            </OfferTableContainer>
        </StyledOfferTable>
    )
}

export default OfferTable