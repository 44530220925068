import styled from "styled-components";
import theme from "../../../themes/default.theme";

interface Props {

}

export const StyledMobileNavigation = styled.div<Props>`
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: calc(100vw - 20px);
    height: 50px;
    margin-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 90;
    background-color: ${theme.colors.accent};
    box-shadow: 0 5px 15px ${theme.colors.shadow};
`