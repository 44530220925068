import { ImpressumSection, ImpressumTitle, IText, StyledImpressum, BackLink } from "./impressum.styled"
import { useSelector, useDispatch } from 'react-redux';

type Props = {

}

const Impressum: React.FC<Props> = (props: Props) => {
    return (
        <StyledImpressum>
                <ImpressumTitle>Impressum</ImpressumTitle>
            <ImpressumSection>
                <IText>
                Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.
                </IText>
            </ImpressumSection>
            <ImpressumSection>
                <IText>
                Der Zauninger - Uwe Thomas
                Greithweg 35, 8403 Lebring-Sankt Margarethen
                Österreich
                </IText>
            </ImpressumSection>
            <ImpressumSection>
                <IText>
                Unternehmensgegenstand: Handel und Verkauf / Onlinehandel
                <br />
                UID-Nummer: ATU66383647
                </IText>
                <IText>
                Firmenbuchgericht: Landesgericht für Zivilrechtssachen Graz
                <br />
                Firmensitz: Lebring
                </IText>
            </ImpressumSection>
            <ImpressumSection>
                <IText>
                    Tel.: +43-(0)680-312 15 46
                </IText>
                <IText>
                    E-Mail: info@zauninger.at
                </IText>
            </ImpressumSection>
            <ImpressumSection>
                <IText>
                    Mitglied bei: WKO, Landesinnung
                </IText>
                <IText>
                    Berufsrecht: Gewerbeordnung: www.ris.bka.gv.at
                </IText>
                <IText>
                    Aufsichtsbehörde/Gewerbebehörde: Bezirkshauptmannschaft Leibnitz
                </IText>
                <IText>
                    Verleihungsstaat: Österreich
                </IText>
            </ImpressumSection>
            <ImpressumSection>
                <IText>
                EU Streitschlichtung
                </IText>
                <IText>
                Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
                </IText>
                <IText>
                Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter http://ec.europa.eu/odr?tid=121701575 zu richten.
                </IText>
            </ImpressumSection>
            <ImpressumSection>
                <IText>
                Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
                </IText>
                <IText>
                Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                </IText>
            </ImpressumSection>
            <ImpressumSection>
                <IText>
                Haftung für Inhalte
                </IText>
                <IText>
                    Wir sind um die Richtigkeit der Inhalte auf unserer Website bemüht. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
                    <br />
                    Keine Inhalte werden bewusst falsch dargestellt. Sollten Sie dennoch auf eine fehlerhafte oder unvollständige Information stoßen, bitten wir Sie, uns dies umgehend mitzuteilen.
                </IText>
            </ImpressumSection>
            <ImpressumSection>
                <IText>
                    Produkte, Preisangaben sowie Abbildungen können sich von der Realität unterscheiden. Wir behalten uns das Recht vor, Änderungen an den Produkten, Preisangaben sowie Abbildungen vorzunehmen.
                    <br />
                    Hier dargestellte Inhalte dienen lediglich als Informationsquelle beziehungsweise Preise als Richtwerte. Die tatsächlichen Preise können von den hier dargestellten Preisen abweichen.
                </IText>
            </ImpressumSection>
            <ImpressumSection>
                <IText>
                    Urheberrechtsnachweis
                </IText>
                <IText>
                    Alle Inhalte dieser Website (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
                </IText>
            </ImpressumSection>
            <ImpressumSection>
                <BackLink href='/'>zum Konfigurator</BackLink>
            </ImpressumSection>
        </StyledImpressum>
    )
}

export default Impressum