import styled from "styled-components";

interface Props {

}

export const StyledPage = styled.div<Props>`
    @media (max-width: 768px) {
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 100px;
        width: calc(100vw - 60px);
        height: calc(100vh - 50px - 50px - 30px);
    }
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw - 200px);
    height: calc(100vh - 50px - 30px);
    margin-top: 50px;
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: start;
    text-align: center;
    overflow-x: hidden;
    overflow: overlay;
`

export const StyledTitle = styled.div`
    @media (max-width: 768px) {
        font-size: 1.5rem;
        height: 15%;
    }
    @media (max-height: 768px) {
        height: 20%;
    }
    @media (max-height: 800px) {
        height: 25%;
    }
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 30%;
    font-size: 1.6rem;
    font-family: SemiBold;
    user-select: none;
    pointer-events: none;
    flex-grow: 1;
    flex-shrink: 0;
`

export const StyledSelection = styled.div`
    @media (max-width: 768px) {
        min-height: calc(85% - 20px);
        margin-bottom: 20px;
    }
    @media (max-height: 768px) {
        min-height: calc(80% - 20px);
        margin-bottom: 20px;
    }
    @media (max-height: 800px) {
        min-height: calc(75% - 20px);
        margin-bottom: 20px;
    }
    flex-grow: 1;
    flex-shrink: 0;
    min-height: 70%;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    row-gap: 10px;
`

const StyledStepPage = {
    StyledPage,
    StyledTitle,
    StyledSelection
}

export default StyledStepPage