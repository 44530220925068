import React from 'react'
import { animated } from '@react-spring/web';
import { useSelector, useDispatch } from 'react-redux';

import { useMediaQuery } from 'react-responsive';

import { State } from '../../../../redux/types';
import { setVariant } from '../../../../redux/actions';

import { StyledPage, StyledSelection, StyledContent, StyledSummaryContainer } from './optionsPage.styled';

import Summary from '../../../../components/Summary/summary';
import Card from '../../../../components/Card/card';

import { productList, ProductVariantNames, MaterialProductVariantMap} from '../../../../common/products'

type Props = {
    style: any
}

const OptionsPage: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();
    const { product, material, variant, color, mode } = useSelector((state: State) => state.configurator);

    const isCardSelected = (id: string) => {
        if (variant) {
            return id === variant;
        }
        return false;
    }

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <StyledPage as={animated.div} style={props.style} >
            {mode && <StyledContent>
                <StyledSelection>
                    {Object.entries(MaterialProductVariantMap[material!!]).map(([key, value]) => (
                        <Card image={productList[product!!].images[value]}
                            color={color}
                            text={ProductVariantNames[value]}
                            onClick={() => dispatch(setVariant(value, isMobile))}
                            key={key}
                            selected={isCardSelected(value)} />
                    ))}
                </StyledSelection>
            </StyledContent>}
            <StyledSummaryContainer>
                {mode && <Summary />}
            </StyledSummaryContainer>
        </StyledPage>
    )
}

export default OptionsPage