import styled from "styled-components";

interface Props {
    
}


export const StyledInput = styled.div<Props>`
    width: 120px;
    background: ${({ theme }) => (theme.colors.controls)};
    border: 1px solid ${({ theme }) => (theme.colors.border)};
    border-radius: 30px;
    box-shadow: 1px 1px 5px ${({ theme }) => (theme.colors.shadow)};
    display: flex;
    align-items: flex-end;
    align-content: center;
    justify-content: center;
    text-align: center;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 5px;
    input {
        background: transparent;
        border: none;
        outline: none;
        color: #000;
        font-family: Regular;
        height: 100%;
        font-size: 1rem;
        width: 100%;
        margin-right: 5px;
        align-self: center;
        -webkit-app-region: no-drag;
        -webkit-user-drag: none; 
        &::placeholder {
            color: ${({ theme }) => (theme.colors.deactivated)};
        }
    }
    &:focus-within {
        outline: none;
        border: 1px solid ${({ theme }) => (theme.colors.accent)};
        box-shadow: 1px 1px 5px ${({ theme }) => (theme.colors.accent)};
    }
`

export const StyledInputContainer = styled.div<Props>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
`

export const StyledInputLabel = styled.div<Props>`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    font-family: SemiBold;
    margin-bottom: 5px;
    user-select: none;
    pointer-events: none;
`

export const StyledInputUnitLabel = styled.div<Props>`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    height: 100%;
    user-select: none;
    pointer-events: none;
`