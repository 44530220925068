import { Product, Material, ProductVariants, GateOption, PostOption } from '../common/products';
import { Size } from '../redux/types'

export function getProductId(product: Product, material: Material, size: Size, variant: ProductVariants, gateOption: GateOption): string {
    const pid = `${product}-${material}-${product === Product.fence ? variant : gateOption}-${size.height}x${size.width}`;
    return pid;
}

export function getPostPid(length: number, variant: PostOption): string {
    return `pf-${variant}-${length}`;
}