import styled from "styled-components";

interface Props {

}

export const StyledBackgroundSlideshow = styled.div<Props>`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
    background-color: #fff;
`

interface BackgroundImageProps {
    active: boolean
}

export const StyledBackgroundImage = styled.img<BackgroundImageProps>`
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    object-position: center;
    opacity: ${({active}) => !active ? '0' : '1'};
    filter: blur(0.8vw) opacity(0.5);
    transition: opacity 2s ease-in-out;
`