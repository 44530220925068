import styled from "styled-components";
import { lighten } from 'polished';
import theme from "../../themes/default.theme";

import { Material, Color } from '../../common/products'

export enum CardSize {
    normal = 'normal',
    small = 'small'
}

interface Props {
    size: CardSize;
    selected: boolean;
}

const Card = styled.div<Props>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 300px;
    border: 1px solid ${theme.colors.border};
    border-radius: 10px;
    overflow: hidden;
    margin: 10px;
    box-shadow: 0 5px 15px ${theme.colors.shadow};
    background-color: ${theme.colors.primary};
    &:hover {
        border: 1px solid ${theme.colors.accent};
        filter: saturate(1);
    }
    filter: ${({ selected }) => (!selected ? 'saturate(0)' : 'saturate(1)')};
`;

interface ImageProps {
    color?: Color | null;
    material?: Material | null;
}

const colorTint = (color: Color | undefined | null, material: Material | undefined | null) => {
    if (material !== null && material !== undefined) {
        switch(material) {
            case Material.sheet:
                return 'none'
            case Material.aluminium:
                return 'brightness(1.3) saturate(0.5)'
        }
    }
    else if (color !== null && color !== undefined) {
        switch(color) {
            case Color.anthracite:
                return 'none'
            case Color.silver:
                return 'brightness(1.3) saturate(0.5)'
            case Color.green:
                return 'sepia(1) hue-rotate(70deg) brightness(0.7)'
        }
    }
    else {
        return 'none'
    }
}

const Image = styled.img<ImageProps>`
    margin-top: 10px;
    margin-bottom: 10px;
    height: 300px;
    width: auto;
    max-width: 100%;
    user-select: none;
    pointer-events: none;
    filter: ${({ color, material }) => (colorTint(color, material))};
    object-fit: contain;
`;

const Text = styled.div`
    width: 100%;
    height: 30px;
    padding: 10px 0px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    user-select: none;
    pointer-events: none;
    font-family: SemiBold;
    font-size: 1.2rem;
    background-color: ${theme.colors.accent};
`;


interface SlideOutProps {
    slideOut: boolean;
}

const SlideOut = styled.div<SlideOutProps>`
    // always show on mobile
    @media (max-width: 768px) {
        max-height: 200px;
        padding: 10px 20px 10px 20px;
        border-top: 1px solid ${theme.colors.border};
    }
    @media (max-height: 768px) and (min-width: 769px) {
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 2px solid ${theme.colors.accent};
        background-color: ${lighten(0.06, theme.colors.secondary)};
    }
    width: calc(100% - 40px);
    max-height: ${({ slideOut }) => (slideOut ? '200px' : '0')};
    background-color: ${theme.colors.secondary};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: ${({ slideOut }) => (slideOut ? '10px 20px 10px 20px' : '0px 20px 0px 20px')};
    text-align: justify;
    user-select: none;
    pointer-events: none;
    transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
`;

const StyledCard = {
    Card,
    Image,
    Text,
    SlideOut
};

export default StyledCard;