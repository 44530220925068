import React from 'react';
import ReactDOM from 'react-dom/client';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import { store, persistor } from './redux/store';


import BackgroundSlideshow from './components/BackgroundSlideshow/backgroundSlideshow';
import PageHeader from './components/PageHeader/pageHeader';
import PageFooter from './components/PageFooter/pageFooter';
import Impressum from './components/Impressum/impressum';

import Configurator from './pages/Configurator/configurator';

import defaultTheme from './themes/default.theme';
import GlobalStyle from './globalStyle';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const App: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyle />
          <Router>
            {!isMobile && <BackgroundSlideshow />}
            <PageHeader />
            <Routes>
              <Route path="/" element={<Configurator />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
            <PageFooter />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);