import theme from "../../themes/default.theme";
import styled from "styled-components";

export const StyledImpressum = styled.div`
    margin: 9vh auto 6vh auto;
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: auto;
    background-color: ${theme.colors.primary};
    border-radius: 30px;
    border: 1px solid ${theme.colors.accent};
    box-shadow: 0px 5px 15px 0px ${theme.colors.shadow};
    overflow: hidden;
`

export const ImpressumTitle = styled.div`
    text-align: center;
    width: 100%;
    color: ${theme.colors.accent};
    font-family: SemiBold;
    font-size: 1.5rem;
    user-select: none;
    pointer-events: none;
    margin-top: 10px;
`

export const ImpressumSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 20px;
    width: 100%;
`

export const IText = styled.p`
    font-size: 1rem;
    margin: 0 5vw;
    user-select: all;
    text-align: center;
`

export const BackLink = styled.a`
    color: ${theme.colors.accent};
    font-family: SemiBold;
    font-size: 1.2rem;
    user-select: auto;
    pointer-events: all;
    text-decoration: none;
    &:hover {
        color: ${theme.colors.border};
        text-decoration: underline;
    }
`