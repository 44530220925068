import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { gotoStep } from '../../../../redux/actions';

import { StyledStep, StyledStepIcon, StyledStepTitle } from './navStep.styled';
import { State, Step } from '../../../../redux/types';

interface Props {
    number: number;
    step: Step;
    title: string;
}

export const NavStep: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();
    const { step, lastCompletedStep } = useSelector((state: State) => state.configurator);
    const isCurrent = () => {
        return step === props.step;
    }
    const isActive = () => {
        if (lastCompletedStep) {
            return lastCompletedStep >= props.step - 1;
        } else {
            return props.step === 1;
        }
    }
    const clickHandler = () => {
        if (!isCurrent() && isActive()) {
            dispatch(gotoStep(props.step));
        }
    }
    return (
        <StyledStep isCurrent={isCurrent()} isActive={isActive()} onClick={clickHandler}>
            <StyledStepIcon>{props.number}</StyledStepIcon>
            <StyledStepTitle>{props.title}</StyledStepTitle>
        </StyledStep>
    )
}

export default NavStep