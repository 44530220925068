import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoMdArrowRoundBack } from 'react-icons/io';
import {  animated } from '@react-spring/web';

import { StyledDesktopNavigation, StyledDesktopBackButton, StyledDesktopNavBar } from './desktopNavigation.styled';

import Steps, { NavStepType } from '../steps';

import NavStep from './NavStep/navStep';
import { State } from '../../../redux/types';

import { goBack } from '../../../redux/actions';

interface Props {
    style: any
}


export const Navigation: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();
    const { step } = useSelector((state: State) => state.configurator);

    return (
        <StyledDesktopNavigation as={animated.div} style={props.style} >
            <StyledDesktopBackButton active={step > 1} onClick={() => step > 1 ? dispatch(goBack()) : null} >
                <IoMdArrowRoundBack />
            </StyledDesktopBackButton>
            <StyledDesktopNavBar>
                {Steps.map((step: NavStepType) => <NavStep key={step.id} number={step.id} step={step.step} title={step.title} />)}
            </StyledDesktopNavBar>
        </StyledDesktopNavigation>
    )
}

export default Navigation